<template>
  <div class="aboutUs-container">
    <header-nav></header-nav>
    <div class="main">
      <div class="main-header"></div>
      <div class="certificate">
        <div class="solutionName">{{ solutionInfo.title }}</div>
        <div v-html="solutionInfo.content"></div>
      </div>
    </div>

    <footer-nav></footer-nav>

    <!-- 定位栏 -->
    <location-column></location-column>
  </div>
</template>

<script>
import headerNav from "@/components/header-nav";
import footerNav from "@/components/footer-nav";
import { getSolutionDetail } from "@/api/newWebsite";
export default {
  name: "cooperation",
  components: { headerNav, footerNav },
  metaInfo: {
    meta: [
      {
        name: "keywords",
        content:
          "新巢天诚NB-IoT智能门锁，CAT.1物联网锁，Wi-Fi智能锁，4G智能门锁，校园智能门锁，保障性住房管理平台，公租房管理系统，人才公寓管理系统，网约房（出租屋管理系统），智慧校园后勤管理系统，智慧园区企业后勤管理系统。",
      },
      {
        name: "description",
        content:
          "江苏新巢天诚智能技术有限公司（简称：天诚）专注于物联网多形态智能硬件的产品研发与制造，为不同垂直领域的客户提供整体解决方案服务，深度赋能公租房.人才公寓、智慧公寓、人才公寓、保障性住房、智慧校园、企业园区宿舍、智慧后勤，网约房（出租屋）等行业应用场景。天诚自主研发的多模态计算机视觉与生物识别物联网锁，采用NB-IoT、CAT.1、Wi-Fi、4G,5G等无线通讯技术。打造整体的智能硬件解决方案。",
      },
    ],
  },
  data() {
    return {
      solutionInfo: {},
    };
  },
  methods: {
    getSolutionDetail(id) {
      getSolutionDetail({
        id: id,
      })
        .then((res) => {
          if (res.code == 0) {
            this.solutionInfo = res.data;
            // console.log("解决方案详情", this.solutionInfo);
          } else {
            this.$Message.error(res.message);
          }
        })
        .catch((err) => {
          this.$Message.error("获取解决方案详情失败,请稍后再试");
        });
    },
  },
  created() {
    let id = this.$route.query.id;
    if(id == '8819005328326656'){       //如果进入解决方案-校园，重新定位新版页面
      this.$router.push({
        name: "school",
      });
    }else if(id == '8818659390521344'){ //如果进入解决方案-园区，重新定位新版页面
      this.$router.push({
        name: "park",
      });
    }else if(id == '8817824614973440'){ //如果进入解决方案-公租房，重新定位新版页面
      this.$router.push({
        name: "gzf",
      });
    }else if(id == '4124609738611097'){ //如果进入解决方案-人才公寓，重新定位新版页面
      this.$router.push({
        name: "hotel",
      });
    }else{
      this.getSolutionDetail(id);
    }
  },
  mounted() {
    document.title = this.$store.state.searchTitle;
    console.log("-----", this.$store.state.searchTitle);
  },
};
</script>

<style scoped>
.aboutUs-container {
  /* min-width: 1366px; */
}
.main {
  width: 100%;
  padding-top: 80px;
}
.main-header {
  width: 100%;
  height: 386px;
  text-align: center;
  background-image: url("../assets/images/new/solution.jpeg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.header-content {
  height: 160px;
  margin: auto;
  padding-top: 120px;
  padding-left: 20px;
}
.title {
  font-size: 40px;
  margin-top: 30px;
  font-weight: bold;
}
.contact_list {
  margin-top: 40px;
  width: 1200px;
  margin: auto;
}
.line {
  width: 80px;
  margin: auto;
  border-bottom: 2px solid #d34100;
  margin-top: 10px;
}
.content {
  font-size: 15px;
  padding-top: 20px;
  color: #333333;
  margin: auto;
  text-align: left;
  letter-spacing: 2px;
  line-height: 25px;
}
.viedo {
  width: 1200px;
  height: 520px;
  margin: auto;
  margin-top: 30px;
  /* background-image: url("../assets/images/about/mv.png"); */
}
.about_list {
  width: 1200px;
  height: 400px;
  margin: auto;
  margin-top: 50px;
}
.about_list p {
  font-size: 16px;
  line-height: 24px;
  color: #878a95;
  font-weight: 400;
}
.certificate {
  max-width: 1000px;
  margin: auto;
  margin-top: 40px;
}

.solutionName {
  font-size: 35px !important;
  letter-spacing: 0px;
  font-weight: bold;
  color: #1d4b73;
  /* line-height: 60px; */
  /* display: inline-block; */
  position: relative;
  margin-top: 30px;
  padding-bottom: 25px;
  margin-bottom: 12px;
}
.solutionName:after {
  position: absolute;
  content: "";
  height: 3px;
  width: 60px;
  background-color: #cfa972;
  bottom: 20px;
  left: calc(50% - 30px);
}
@media screen and (max-width: 768px) {
  .main {
    padding-top: 50px;
  }
  .main-header {
    height: 166px;
  }
  .solutionName {
    font-size: 24px !important;
  }
}
</style>